<template>
  <q-page>
    <div class="media">
      <!-- <h1>This is an media page</h1> -->
      <div class="row">
        <template v-for="(data, idx) in mediaCardList" :key="idx">
          <div class="col-12 col-md-4">
            <MediaCard :title="data.title" :src="data.src" />
          </div>
        </template>
      </div>
    </div>
  </q-page>
</template>

<script>
// @ is an alias to /src
import MediaCard from "@/components/MediaCard.vue";

export default {
  setup() {
    return {
      mediaCardList: [
        {
          title: "服飾設計與經營學系學系簡介Brief Introduction",
          src: "https://www.youtube.com/embed/zZ-gnMWI3JA"
        },
        {
          title: "服飾設計與經營學系 2022AW台北時裝週展演",
          src: "https://www.youtube.com/embed/C3bJH2SDkMs"
        },
        {
          title: "服飾設計與經營學系期末學生作品Runway show",
          src: "https://www.youtube.com/embed/2u53Um4RDhk"
        },
        {
          title: "董亭言設計師 2022AW Taipei Fashion week",
          src: "https://www.youtube.com/embed/kERPzErOpIU"
        },
        {
          title: "2021台北時裝週設計師 江玟諺",
          src: "https://www.youtube.com/embed/NMua-w3GQx0"
        },
        {
          title: "2021台北時裝週設計師 黃莉瀅",
          src: "https://www.youtube.com/embed/oFVfD09O1ns"
        },
        {
          title: "2021台北時裝週設計師 曾琬凌 蔡耀東 施亮熙",
          src: "https://www.youtube.com/embed/1KXZQ1AKhhg"
        },
        {
          title: "2021台北時裝週設計師 戴子紘",
          src: "https://www.youtube.com/embed/rusr9OZe_wU"
        },
        {
          title: "2021台北時裝週設計師 游閔盛",
          src: "https://www.youtube.com/embed/TRNPauVPUuw"
        },
        {
          title: "2021台北時裝週設計師 張庭瑋",
          src: "https://www.youtube.com/embed/SRPYAwUL6pQ"
        },
        {
          title: "2021台北時裝週設計 謝欣儒 劉媛婈",
          src: "https://www.youtube.com/embed/ikLgCgI4GSE"
        },
        {
          title: "2021台北時裝週設計 白季葳 王亮婷",
          src: "https://www.youtube.com/embed/AuWGgRIS6C8"
        },
        {
          title: "2021台北時裝週設計師 廖家平",
          src: "https://www.youtube.com/embed/x106JTC0gHk"
        },
        {
          title: "台北時裝週 服經系",
          src: "https://www.youtube.com/embed/_OIThRzDNNA"
        },
        {
          title: "2021畢業靜態展",
          src: "https://www.youtube.com/embed/BnF9vZ3sO0E"
        },
        {
          title: "時尚風格造型工作營",
          src: "https://www.youtube.com/embed/e792d5Zvk78"
        },
        {
          title: "畢業設計展",
          src: "https://www.youtube.com/embed/GztYC63v7sk"
        },
        {
          title: "義大利移地教學1080p",
          src: "https://www.youtube.com/embed/gGPJbY9wbTU"
        }
      ]
    };
  },
  name: "Media",
  components: { MediaCard }
};
</script>
